export const SET_ALL_ORDERS_START_DATE = "SET_ALL_ORDERS_START_DATE";
export const SET_ALL_ORDERS_END_DATE = "SET_ALL_ORDERS_END_DATE";

export const SET_UPCOMING_ORDERS_START_DATE = "SET_UPCOMING_ORDERS_START_DATE";
export const SET_UPCOMING_ORDERS_END_DATE = "SET_UPCOMING_ORDERS_END_DATE";

export const SET_DUE_RETURN_ORDERS_START_DATE = "SET_DUE_RETURN_ORDERS_START_DATE";
export const SET_DUE_RETURN_ORDERS_END_DATE = "SET_DUE_RETURN_ORDERS_END_DATE";

export const SET_PREBOOKING_ORDERS_START_DATE = "SET_PREBOOKING_ORDERS_START_DATE";
export const SET_PREBOOKING_ORDERS_END_DATE = "SET_PREBOOKING_ORDERS_END_DATE";

export const SET_COMPLETED_ORDERS_DATE = "SET_COMPLETED_ORDERS_DATE";

export const SET_REFUNDED_ORDERS_START_DATE = "SET_REFUNDED_ORDERS_START_DATE";
export const SET_REFUNDED_ORDERS_END_DATE = "SET_REFUNDED_ORDERS_END_DATE";