// import { ex } from "@fullcalendar/core/internal-common"


/* TOYS */
export const GET_TOYS = "GET_TOYS"
export const GET_TOYS_SUCCESS = "GET_TOYS_SUCCESS"
export const GET_TOYS_FAIL = "GET_TOYS_FAIL"

/**
 * add TOY
 */
export const ADD_NEW_TOY = "ADD_NEW_TOY"
export const ADD_TOY_SUCCESS = "ADD_TOY_SUCCESS"
export const ADD_TOY_FAIL = "ADD_TOY_FAIL"

/**
 * Edit TOY
 */
export const UPDATE_TOY = "UPDATE_TOY"
export const UPDATE_TOY_SUCCESS = "UPDATE_TOY_SUCCESS"
export const UPDATE_TOY_FAIL = "UPDATE_TOY_FAIL"

/**
 * Delete TOY
 */
export const DELETE_TOY = "DELETE_TOY"
export const DELETE_TOY_SUCCESS = "DELETE_TOY_SUCCESS"
export const DELETE_TOY_FAIL = "DELETE_TOY_FAIL"

// Toy availability: 
export const UPDATE_TOY_AVAILABILITY = "UPDATE_TOY_AVAILABILITY";

export const UPDATE_TOY_AVAILABILITY_SUCCESS = "UPDATE_TOY_AVAILABILITY_SUCCESS";
export const UPDATE_TOY_AVAILABILITY_FAIL = "UPDATE_TOY_AVAILABILITY_FAIL";

/* CATEGORIES */
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL";

// Loading 
export const SET_LOADING = "SET_LOADING";